import React from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import sunpuMethods from 'core/sunpu-methods';
import { Button, Checkbox, Form, Input, Space, Typography, Row, Col, Select, InputNumber } from 'antd';
import { notifications } from 'components/notification';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import $auth from 'stores/auth';

const { Title } = Typography;
const { TextArea } = Input;

const SPECIAL_REQUEST_TYPES = {
    NotSupported: '0',
    PartiallySupported: '1',
    FullySupported: '2',
};

const CronInput = ({ value, onChange }) => (
    <Cron value={value} setValue={onChange} disabled={!$auth.permitted('SupplierManagement')} />
);

const SupplierTechnicalDetailsPage = ({ isReadOnly, supplier, code, loadSupplier }) => {
    const [supplierPageForm] = Form.useForm();

    const submit = (values) => {
        let customHeaders = null;
        try {
            if (values.customHeaders) customHeaders = JSON.parse(values.customHeaders);
        } catch (e) {}
        const { isSupportedSpecialRequestChangeAfterDeadline, specialRequestsSupport } = values;

        let body = {
            ...supplier,
            ...values,
            isSupportedSpecialRequestChangeAfterDeadline:
                specialRequestsSupport === '2' && !isSupportedSpecialRequestChangeAfterDeadline,
            customHeaders,
        };

        API.post({
            sunpu: sunpuMethods.supplierDataTechnicalDetails(code),
            body,
            success: () => {
                loadSupplier();
                notifications('Supplier Saved!', 'success');
            },
        });
    };

    if (code && !supplier) return <Loader />;

    return (
        <>
            <Form
                form={supplierPageForm}
                initialValues={
                    supplier
                        ? {
                              ...supplier,
                              customHeaders: supplier.customHeaders
                                  ? JSON.stringify(supplier.customHeaders)
                                  : '',
                              specialRequestsSupport:
                                  SPECIAL_REQUEST_TYPES?.[supplier.specialRequestsSupport] || null,
                              isSupportedSpecialRequestChangeAfterDeadline:
                                  supplier.specialRequestsSupport === '2' &&
                                  !supplier?.isSupportedSpecialRequestChangeAfterDeadline,
                          }
                        : {}
                }
                layout="vertical"
                onFinish={submit}
                style={{ maxWidth: 1000 }}
                className={isReadOnly && 'disable-full-form'}
                disabled={isReadOnly}
            >
                <Title level={4} style={{ marginBottom: 20 }}>
                    Technical Details
                </Title>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="status" label="Status">
                            <Select placeholder="Select">
                                <Option value="Enabled">Active</Option>
                                <Option value="Disabled">Inactive</Option>
                                <Option value="TestOnly">Active for Test Only</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="supplierId" label="Supplier ID">
                            <Input placeholder="Supplier ID" disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            name="contractType"
                            label="Contract Type"
                            rules={[{ required: true, message: 'Please enter Contract Type' }]}
                        >
                            <Select placeholder="Select">
                                <Option value="NotSpecified">Not Specified</Option>
                                <Option value="OnlineAndOffline">Online / Offline</Option>
                                <Option value="Offline">Offline</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="supplierName"
                            label="Supplier Name"
                            rules={[{ required: true, message: 'Please enter Supplier Name' }]}
                        >
                            <Input placeholder="Supplier Name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="supplierCode" label="Supplier Code">
                            <Input placeholder="Please Enter Supplier Code" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="connectorUrl"
                            label="Connector URL"
                            rules={[{ required: true, message: 'Please enter Connector URL' }]}
                        >
                            <Input placeholder="Connector URL" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="giataSupplierCode" label="GIATA Supplier Code">
                            <Input placeholder="Please Enter GIATA Supplier Code" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="channelType"
                            label="Channel Type"
                            rules={[{ required: true, message: 'Please Select' }]}
                        >
                            <Select placeholder="Select">
                                <Option value="NotApplicable">N/A</Option>
                                <Option value="SWITCH">SWITCH</Option>
                                <Option value="DirectConnect">Direct Connect</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.contractType !== currentValues.contractType
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('contractType') !== 'Offline' ? (
                                    <Form.Item
                                        name="supplierType"
                                        label="Supplier Type / Channel"
                                        rules={[{ required: true, message: 'Please Select' }]}
                                    >
                                        <Select placeholder="Select Supplier Type">
                                            <Option value="StaticContract">Static Contract</Option>
                                            <Option value="DirectConnectivity">Direct Connectivity</Option>
                                            <Option value="ChannelManager">Channel Manager</Option>
                                            <Option value="Party">3rd Party</Option>
                                        </Select>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="connectivityPlatform" label="Connectivity Platform">
                            <Select placeholder="Select">
                                <Option value="NotSpecified">Not Specified</Option>
                                <Option value="Travelgate">Travelgate</Option>
                                <Option value="Netstorming">Netstorming</Option>
                                <Option value="Juniper">Juniper</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="connectorGrpcEndpoint" label="Connector GRPC Endpoint">
                            <Input placeholder="Connector GRPC Endpoint" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="connectorUpdaterEndpoint" label="Connector Updater Endpoint">
                            <Input placeholder="Connector Updater Endpoint" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="specialRequestsSupport" label="Special Requests Support">
                            <Select
                                placeholder="Select"
                                onChange={() =>
                                    supplierPageForm.setFieldValue(
                                        'isSupportedSpecialRequestChangeAfterDeadline',
                                        false
                                    )
                                }
                            >
                                <Option value="0">Not Supported</Option>
                                <Option value="1">Partially Supported</Option>
                                <Option value="2">Fully Supported</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.specialRequestsSupport !== currentValues.specialRequestsSupport
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('specialRequestsSupport') === '2' ? (
                                <Col span={12}>
                                    <Form.Item
                                        name="specialRequestLimit"
                                        label="Number of Special Request Modifications"
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            placeholder="Unlimited"
                                            min={1}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.specialRequestsSupport !== currentValues.specialRequestsSupport
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('specialRequestsSupport') === '2' ? (
                                <Col span={12}>
                                    <Form.Item
                                        name="isSupportedSpecialRequestChangeAfterDeadline"
                                        valuePropName="checked"
                                    >
                                        <Checkbox style={{ width: '100%' }}>
                                            Disable modifications of special requests within deadline
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            ) : null
                        }
                    </Form.Item>
                </Row>
                <Title level={4}>Updater</Title>
                <Form.Item name="connectorUpdaterCronExpression" label="Connector Updater Cron Expression">
                    <Input placeholder="Connector Updater Cron Expression" />
                </Form.Item>
                <Form.Item name="connectorUpdaterCronExpression">
                    <CronInput />
                </Form.Item>
                <Title level={4}>Multi-Room & VCC</Title>
                <Space direction="vertical">
                    <Space style={{ marginBottom: -35 }}>
                        <Form.Item name="isMultiRoomFlowSupported" valuePropName="checked">
                            <Checkbox defaultChecked={false}>Multi-Room Flow Supported</Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="isDirectContract"
                            valuePropName="checked"
                            style={{ marginLeft: 20, marginRight: 20 }}
                        >
                            <Checkbox defaultChecked={false} disabled>
                                Is Direct Contract
                            </Checkbox>
                        </Form.Item>
                        <Form.Item name="isContractDescriptionVisible" valuePropName="checked">
                            <Checkbox defaultChecked={false}>Is Contract Description Visible</Checkbox>
                        </Form.Item>
                    </Space>
                    <Space>
                        <Form.Item name="canUseGrpc" valuePropName="checked">
                            <Checkbox defaultChecked={false}>Can use gRPC</Checkbox>
                        </Form.Item>
                        <Form.Item name="canUseVcc" valuePropName="checked" style={{ marginLeft: 20 }}>
                            <Checkbox defaultChecked={false}>Can use VCC</Checkbox>
                        </Form.Item>
                    </Space>
                </Space>
                <Form.Item name="customHeaders" label="Custom Headers">
                    <TextArea placeholder="Custom Headers (JSON)" />
                </Form.Item>
                <Title level={4}>Limits</Title>
                <Row size="large">
                    <Col span={4} style={{ paddingRight: 30 }}>
                        <Form.Item name={['limits', 'perMonth']} label="Allowed Per Month">
                            <InputNumber style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingRight: 30 }}>
                        <Form.Item name={['limits', 'perDay']} label="Allowed Per Day">
                            <InputNumber style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingRight: 30 }}>
                        <Form.Item name={['limits', 'perHour']} label="Allowed Per Hour">
                            <InputNumber style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingRight: 30 }}>
                        <Form.Item name={['limits', 'perMinute']} label="Allowed Per Minute">
                            <InputNumber style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingRight: 30 }}>
                        <Form.Item name={['limits', 'perSecond']} label="Allowed Per Second">
                            <InputNumber style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="technicalDetailsRemarks" label="Remarks">
                    <TextArea placeholder="Enter" />
                </Form.Item>
                {$auth.permitted('SupplierManagement') && (
                    <Button htmlType="submit" type="primary">
                        Save Changes
                    </Button>
                )}
            </Form>
        </>
    );
};

export default SupplierTechnicalDetailsPage;
