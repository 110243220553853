import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import AgencyHeader from './agency-parts/agency-header';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import AgentsList from './agency-parts/agency-agents';
import AgencyDetails from './agency-parts/agency-details';
import AgencyMarkups from './agency-parts/agency-markups';
import AgencyVerification from './agency-parts/agency-verification';
import AccountsHistory from './agency-parts/accounts-history';
import TransferBalanceAccountOperations from './agency-parts/transfer-balance-account-operations';
import AgencySettings from './agency-parts/agency-settings';
import { Menu } from 'antd';
import { Loader } from 'legacy';
import $auth from 'stores/auth';
import AgentInvitePage from './agent/agent-invite-page';
import AgencyHotels from './agency-parts/hotels/agency-hotels';
import AgencyHistory from './agency-parts/history/agency-history';
import AgencyOrAgentBookings from '../bookings/tabs/agency-bookings/agency-or-agent-bookings';

const AgencyPage = observer(() => {
    useTitle('Agency');

    const [agency, setAgency] = useState(null);
    const [agencyAccounts, setAgencyAccounts] = useState(null);
    const [availableCurrencies, setAvailableCurrencies] = useState(null);
    const { id } = useParams();

    const allMenuItems = [
        {
            label: <Link to="./agents">Agents List</Link>,
            key: 'agents',
            permissions: ['ViewAgents'],
        },
        {
            label: <Link to="./details">Details</Link>,
            key: 'details',
            permissions: ['AgencyManagement'],
        },
        {
            label: <Link to="./markups">Actual Markups</Link>,
            key: 'markups',
            permissions: ['MarkupManagement', 'ViewMarkups'],
        },
        {
            label: <Link to="./transfer-balance">Balance</Link>,
            key: 'transfer-balance',
            permissions: ['BalanceManualCorrection'],
        },
        {
            label: <Link to="./accounts-statement/all">Account Statement</Link>,
            key: 'accounts-statement',
            permissions: ['AgencyBalanceObservation'],
        },
        {
            label: <Link to="./bookings/all">Bookings</Link>,
            key: 'bookings',
            permissions: ['ViewBookings'],
        },
        {
            label: <Link to="./settings">Settings</Link>,
            key: 'settings',
            permissions: ['AgencyManagement'],
        },
        {
            label: <Link to="./verification">Verification & Limits</Link>,
            key: 'verification',
            permissions: ['AgencyVerification'],
        },
        {
            label: <Link to="./hotels">Hotels List</Link>,
            key: 'hotels',
            permissions: ['ViewHotelList'],
        },
        {
            label: <Link to="./history">Modification History</Link>,
            key: 'history',
            permissions: ['ViewAgencies'],
        },
    ];

    const location = useLocation();
    const menuItems = allMenuItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    const loadAgencyAccounts = () => {
        if ($auth.permitted('AgencyBalanceObservation')) {
            API.get({
                url: apiMethods.agenciesAccounts(id),
                success: (data) => setAgencyAccounts(data),
            });
        }
    };

    const loadAgency = () => {
        API.get({
            url: apiMethods.agency(id),
            success: (data) => setAgency(data),
        });
    };

    useEffect(() => {
        loadAgency();
        if ($auth.permitted('AccountReplenish') || $auth.permitted('AgencyBalanceObservation')) {
            loadAgencyAccounts();
        }
    }, [id, $auth.permitted('AccountReplenish'), $auth.permitted('AgencyBalanceObservation')]);

    useEffect(() => {
        if (
            $auth.permitted('AgencyManagement') &&
            (agency?.contractKind === 'OfflineOrCreditCardPayments' ||
                agency?.contractKind === 'VirtualAccountOrCreditCardPayments')
        ) {
            API.get({
                url: apiMethods.agencyAvailableCurrencies(agency.id),
                success: setAvailableCurrencies,
            });
        } else {
            setAvailableCurrencies(null);
        }
    }, [agency, $auth.permitted('AgencyManagement')]);

    if (!agency) {
        return <Loader />;
    }

    return (
        <>
            <AgencyHeader
                accounts={agencyAccounts}
                agency={agency}
                setAgency={setAgency}
                availableCurrencies={availableCurrencies}
            />
            <Menu mode="horizontal" items={menuItems} selectedKeys={location.pathname.split('/')[3] || ''} />
            <Routes>
                <Route path="/agent-invite" element={<AgentInvitePage agency={agency} />} />
                <Route path="/agents" element={<AgentsList agency={agency} />} />
                <Route path="/details" element={<AgencyDetails agency={agency} loadAgency={loadAgency} />} />
                <Route path="/markups/*" element={<AgencyMarkups agency={agency} />} />
                <Route
                    path="/accounts-statement/:accountId"
                    element={<AccountsHistory agency={agency} agencyAccounts={agencyAccounts} />}
                />
                <Route
                    path="/transfer-balance"
                    element={
                        <TransferBalanceAccountOperations
                            agency={agency}
                            agencyAccounts={agencyAccounts}
                            loadAgencyAccounts={loadAgencyAccounts}
                        />
                    }
                />
                <Route path="/bookings/*" element={<AgencyOrAgentBookings agencyId={id} agency={agency} />} />
                <Route
                    path="/settings"
                    element={<AgencySettings agency={agency} loadAgency={loadAgency} />}
                />
                <Route
                    path="/verification"
                    element={
                        <AgencyVerification
                            agency={agency}
                            setAgency={setAgency}
                            agencyAccounts={agencyAccounts}
                            loadAgencyAccounts={loadAgencyAccounts}
                            availableCurrencies={availableCurrencies}
                        />
                    }
                />
                <Route path="/hotels/*" element={<AgencyHotels />} />
                <Route path="/history" element={<AgencyHistory url={apiMethods.agencyHistory(id)} />} />
            </Routes>
        </>
    );
});

export default AgencyPage;
