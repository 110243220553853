const safeString = (val) => val.replace(/'/g, "''");

export const serializeRequest = (values, top, skip) => {
    const filters = [];

    if (values.agencyId) {
        filters.push(`agencyId eq ${values.agencyId}`);
    }

    if (values.agencyName) {
        filters.push(`contains(tolower(agencyName),tolower('${safeString(values.agencyName)}'))`);
    }

    return {
        ...(filters.length
            ? {
                  $filter: filters.join(' and '),
              }
            : {}),
        $top: top,
        $skip: skip,
    };
};
