import React from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';

const TableSearchForm = ({ searchForm, onSubmit }) => (
    <Form form={searchForm} onFinish={onSubmit} layout="vertical" style={{ marginBottom: 30 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col lg={12} xl={6}>
                <Form.Item name="agencyName" label="Agency Name">
                    <Input placeholder="Name" allowClear />
                </Form.Item>
            </Col>

            <Col lg={12} xl={6}>
                <Form.Item name="agencyId" label="ID">
                    <Input placeholder="ID" allowClear />
                </Form.Item>
            </Col>
            <Col span={12} xl={6} style={{ marginTop: 6 }}>
                <Space size="large">
                    <Button type="primary" htmlType="submit" style={{ width: 170 }}>
                        Search
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => {
                            searchForm.resetFields();
                            onSubmit({});
                        }}
                    >
                        Clear Filters
                    </Button>
                </Space>
            </Col>
        </Row>
    </Form>
);

export default TableSearchForm;
