import React, { useEffect, useState } from 'react';
import apiMethods from 'core/methods';
import TablePages from 'components/table-pages';
import usePages from 'components/use-pages';
import { Form } from 'antd';
import { serializeRequest } from './serializer';
import TableSearchForm from './table-search-form';
import { columns } from './web-table-settings';
import { useParams } from 'react-router-dom';

const OpenedAgenciesWeb = () => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();

    const { code } = useParams();

    useEffect(() => {
        form.validateFields().then(setFormValues);
    }, []);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    return (
        <>
            <TableSearchForm searchForm={form} onSubmit={onSubmit} />
            <TablePages
                columns={columns}
                formValues={formValues}
                route={{ url: apiMethods.openedAgenciesWeb(code) }}
                serializeRequest={serializeRequest}
                page={page}
                setPage={setPage}
            />
        </>
    );
};

export default OpenedAgenciesWeb;
